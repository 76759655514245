import regfunc from './form/regfunc';
import close_label from "@/assets/map/close_label.png";
import sOpen from "@/assets/map/open.png"; //关闭图标
import xOpen from "@/assets/map/open1.png"; //关闭图标
import i18n from '@/language/index';
import axios from "axios";
import store from "../store/index";

const replaceParamVal = (url, paramName, replaceWith) => {
    var oUrl = url;
    var re = eval('/(' + paramName + '=)([^&]*)/gi');
    var nUrl = oUrl.replace(re, paramName + '=' + replaceWith);
    return nUrl
}

const isEmpty = (obj) => {    //检测是否为空对象
    return Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;
}

const compare = (a, b) => {      //比较两个时间大小
    return a.getTime() > b.getTime();
}

const getRandomColor = () => {       //获取随机颜色
    return `#${Math.floor(Math.random() * 0xffffff).toString(16)}`;
}

const limitInput = (value) => {       //限制输入框只能输入特定的值
    let groupName = "";
    if (value && value.length > 0) {
        groupName = value.replace(regfunc.trimTow, ' ');  //去除前后空格2个字符只能有一个空格
        groupName = groupName.replace(/[^a-zA-Z0-9\u4e00-\u9fa5\s]/g, '');  ///限制只能输入数字空格英文中文
    }
    return groupName;
}


const stringDirection = (angle) => {  //根据角度计算方向
    var num = parseInt(angle)
    var N = i18n.tc('message.north');
    var E = i18n.tc('message.East');
    var S = i18n.tc('message.South');
    var W = i18n.tc('message.West');
    var p = i18n.tc('message.partial');
    var dir = '';
    let space = store.state.language == 'en' ? " " : "";

    if (num == 0 || num == 360) {  //北
        dir = i18n.tc('message.truenorth');
    } else if (num < 90 && num > 0) {
        if (num < 45) {
            dir = `${N}${space}${p}${space}${E}${space}${num} °`;
        } else if (num == 45) {
            dir = `${E}${space}${N}${space}${num} °`;
        } else if (num > 45) {
            dir = `${E}${space}${p}${space}${N}${space}(${90 - num}) °`;
        }
    } else if (num == 90) { //东
        dir = i18n.tc('message.Zhengdong');
    } else if (num < 180 && num > 90) {
        if (num < 135) {
            dir = `${E}${space}${p}${space}${S}${space}(${num - 90}) °`;
        } else if (num == 135) {
            dir = `${E}${space}${S}${space}(${num - 90}) °`;
        } else if (num > 135) {
            dir = `${S}${space}${p}${space}${E}${space}(${180 - num}) °`;
        }
    } else if (num == 180) {   //南
        dir = i18n.tc('message.duesouth');
    } else if (num < 270 && num > 180) {

        if (num < 225) {
            dir = `${S}${space}${p}${space}${W}${space}(${num - 180}) °`;
        } else if (num == 225) {
            dir = `${W}${space}${S}${space}(${num - 180}) °`;
        } else if (num > 225) {
            dir = `${W}${space}${p}${space}${S}${space}(${270 - num}) °`;
        }
    } else if (num == 270) { //西
        dir = i18n.tc('message.duewest');
    } else if (num < 360 && num > 270) {
        if (num < 315) {
            dir = `${W}${space}${p}${space}${N}${space}(${num - 270}) °`;
        } else if (num == 315) {
            dir = `${W}${space}${N}${space}(${num - 270}) °`;
        } else if (num > 315) {
            dir = `${S}${space}${p}${space}${W}${space}(${num - 270}) °`;
        }
    }

    return dir;
}

const mapHtml = (item, mapType, type) => {
    let isExceed = item.isExceed;  //判断是否超过五分钟
    var html = "";
    var htmlTow = "";
    if (!type) {
        htmlTow = `
          <div id="rtime${item.id}" style="display: none;" class='user_marker ${item.online != 3 ? 'baidu_grey' : isExceed ? 'baidu_yellow' : ''}' >
            <img onclick="closeLabel(${item.id},1,event)" class="mouse closeLable" src="${close_label}" alt="">
            <div class="user_marker_box" >
              <div>${i18n.tc('message.Name')}：${item.label}</div>
              <div class='baidu_lAbel_space' >${i18n.tc('message.date')}：${item.pot.Rtime}</div>
              <div>${i18n.tc('message.Targeting')}：${item.pot.positionType == 2 ? i18n.tc('message.Basestationpositioning') : i18n.tc('message.BeidouGPS')}</div>
              <div class="user_marker_box" id="expand1${item.id}"  style="${mapType == 'Wgs84' ? '' : 'display: none;'}" >
                <div>${i18n.tc('message.direction')}：${stringDirection(item.pot.Direction)}</div>
                <div>${i18n.tc('message.altitude')}(M)：${item.pot.altitude}</div>
                <div class='baidu_lAbel_space' >${i18n.tc('message.Speed')}：${item.pot.Speed > 0 ? item.pot.Speed.toFixed(2) : item.pot.Speed}</div>
                <div style="width:100%;height:1px;background-color: #fff;margin:10px 0px;" ></div>
                <div style="white-space: break-spaces;font-size: 12px;">${i18n.tc('message.warmreminder')} </div>
              </div>
              <div style="width:100%;text-align: center;margin-top:10px;${mapType == 'Wgs84' ? 'display: none;' : ''}" >
                <img id="expand1${item.id}" style="display: none;" onclick="expand(${item.id},1)" class="mouse margin_auto" src="${sOpen}" alt="">
                <img id="expand2${item.id}" style="display: block;" onclick="expand(${item.id},2)" class="mouse margin_auto" src="${xOpen}" alt="">
              </div>
            </div>
            <div class="big" style="bottom: -12px;" ><div class='small ${item.online != 3 ? 'grey_triangle' : isExceed ? 'grey_yellow' : ''}'></div></div>
          </div>
        `;
        html = `<div id='baidu${item.id}' onmouseover="overMap(${item.id})" onmouseout="outMap(${item.id})" onclick="closeLabel(0,0,event)" class='${mapType == 'Wgs84' ? 'baiduinfo_box2' : 'baiduinfo_box1'}'>
        <div id="rtimelabel${item.id}" class='baiduinfo1 ${item.online != 3 ? 'baidu_grey' : isExceed ? 'baidu_yellow' : ''}'>
          <img onclick="closeLabel(${item.id},1,event)" style="right: 10px;" class="mouse closeLable" src="${close_label}" alt="">
          <div class='baiduinfo_label1'>${item.label}</div>
          <div class="big" style="bottom: -12px;" ><div class='small ${item.online != 3 ? 'grey_triangle' : isExceed ? 'grey_yellow' : ''}'></div></div>
        </div>
        ${htmlTow}
        </div>`;
    }

    return html
}

const multipleMapHtml = (type, item, arr, mapType) => {
    let html = '';
    if (type == 1) {
        html = `<div>
                           <div>
                              <div>${i18n.tc('message.Name')}：${item.label}</div>
                              <div class='baidu_lAbel_space' >${i18n.tc('message.date')}：${item.pot.Rtime}</div>
                              <div>${i18n.tc('message.Targeting')}：${item.pot.positionType == 2 ? i18n.tc('message.Basestationpositioning') : i18n.tc('message.BeidouGPS')}</div>
                              <div>${i18n.tc('message.direction')}：${stringDirection(item.pot.Direction)}</div>
                              <div>${i18n.tc('message.altitude')}(M)：${item.pot.altitude}</div>
                              <div class='baidu_lAbel_space' >${i18n.tc('message.Speed')}：${item.pot.Speed > 0 ? item.pot.Speed.toFixed(2) : item.pot.Speed}</div>
                              <div style="width:100%;height:1px;background-color: #fff;margin:10px auto;" ></div>
                              <div style="white-space: break-spaces;font-size: 12px;">${i18n.tc('message.warmreminder')}</div>
                           </div>
                      </div>`;
    } else if (type == 2) {
        let bool = arr.every(m => m.online != 3);  //全部离线
        let bool1 = arr.every(m => m.online == 3 && m.isExceed);  //判断是否只有在线5分钟没有上报定位的
        let bool2 = arr.every(m => m.online == 3 && m.isExceed || m.online != 3);
        let lab = '';
        arr.forEach((r, i) => {
            // let m = JSON.stringify(r);
            lab += `
          <div onclick="clickShowUserInfo(${r.id},${item.id},event);" class='baidu_label1 ${i == 0 ? 'label_border' : ''} ${r.online != 3 ? 'baidu_grey' : r.isExceed ? 'baidu_yellow' : ''}' >
                <div style="${i == 0 ? 'border: none;' : ''}" class='baidu_lAbel_box baidu_lAbel_space'>${r.label}</div>
          </div>`;
        })
        html = `<div class="baidu_box ${mapType == 'Wgs84' ? 'baidu_lAbel_fixed' : ''}"  id='baidu${item.id}' >
            <div class='baidu_lAbel' id='baidu_lAbel' >
                <img onclick="closeLabel(${item.id},1)" class="mouse closeLable" src="${close_label}" alt="">
                <div>${lab}</div>
                <div class="big" >
                  <div class='small ${bool ? 'grey_triangle' : bool1 || bool2 ? 'grey_yellow' : ''}'></div>
                </div>
            </div>
           <div id="userMarker${item.id}" class="userMarker_din"></div>
        </div>`;
    }
    return html
}

const getMapAddress = (latLngData) => {
    // latLngData = '1.4868981094016747, 103.64408230631442'
    return new Promise(function (resolve, reject) {
        axios.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latLngData + "&location_type=ROOFTOP&result_type=street_address&key=AIzaSyAu2DtsGcSllXewkkkPkZl1XIAGHQWZi9o&async=true").then(res => {
            let site = res.data.results[0].formatted_address;
            // console.log(site)
            resolve(site);
        }).catch(() => {
            console.log(6666)
            reject(null);
        })
    })
}

const JudgingHemisphere = (pot) => {       //判断半球
    let bool = pot.Lng >= 0 ? true : false; //经度  //判断是否为负数 //东西是经度  南北是纬度
    let boolT = pot.Lat >= 0 ? true : false;  //纬度  判断是否为负数 //东西是经度  南北是纬度
    let ballId = store.state.ballId;
    let data = {
        Lng: Number(pot.Lng),
        Lat: Number(pot.Lat)
    }
    switch (ballId) {
        case 2:
            data.Lng = Math.abs(data.Lng);
            data.Lat = Math.abs(data.Lat);
            break;
        case 3:
            data.Lng = bool ? -data.Lng : data.Lng;
            data.Lat = Math.abs(data.Lat);
            break;
        case 4:
            data.Lng = Math.abs(data.Lng);
            data.Lat = boolT ? -data.Lat : data.Lat;
            break;
        case 5:
            data.Lng = bool ? -data.Lng : data.Lng;
            data.Lat = boolT ? -data.Lat : data.Lat;
            break;
    }

    return data
}

const getSpaceLength = (value) => {  //获取空格长度
    return (value.match(/ /g) || []).length
}

const getRectangleDrop = (event) => {   //获取矩形定位点
    let point = "";
    var bounds = event.getBounds();
    if (bounds !== null) {
        var ne = bounds.getNorthEast(); // 东北角  
        var sw = bounds.getSouthWest(); // 西南角  
        point = `${sw.lng()},${sw.lat()}|${sw.lng()},${ne.lat()}|${ne.lng()},${ne.lat()}|${ne.lng()},${sw.lat()}|${sw.lng()},${sw.lat()}`;
    }
    return point
}

export default { replaceParamVal, isEmpty, compare, getRandomColor, limitInput, mapHtml, multipleMapHtml, getMapAddress, JudgingHemisphere, getSpaceLength, getRectangleDrop }