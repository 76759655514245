<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {
  queryMonitorGroup,
  joinMonitorGroup,
  removeMonitorGroup,
  // addMember,
  queryUserService
} from "@/administration/dispatchLntercom.js";
import EventBus from "@/eventBus/eventBus.js";
import global from "@/method/globalMethod.js";
import { _throttle } from "@/method/utils.js";
import tipsVoice from "@/method/wav/tips.wav";
import beasconfig from "@/api/beasconfig.js";
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
var ws;
var token;
var checknet;
var login_count;
export default {
  data() {
    return {
      userInfo: {}, //用户信息
      map: "",
      temporaryTime: null, //计时器
      timeNum: 0, //计时时间
      isTemporaryGroup: 0, //判断是否为临时群组  0为固定 1为临时
      loginState: 0,
      refreshList: null,
      groupUserData: [],    //处理大数据
      fullduplex: {    //全双工
        account: 0,  //会话id
        uid: 0,     //
        accept: 0,    //是否接受
        with_video: ''  // 0拒绝  1接听  2正忙  4 未接通
      }
    };
  },
  created() {
    let that = this;
    checknet = true;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      that.$store.state.userInfo = userInfo;
    }
    ws = new net("ws://127.0.0.1:16668", true, that.netstate, proto);
    that.$store.state.ws = ws;
    that.init();
    // document.addEventListener('visibilitychange', function () {
    //   let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //   console.log(that.$store.state.userInfo.User_ID)
    //   if(that.$store.state.userInfo.User_ID != userInfo.User_ID){
    //     that.$MessageWarning('当前登录账号已过期！可能是由于您在同一个浏览器同时登录了多个账号导致的。是否重新登录？');
    //   }
    // })
  },
  mounted() { },
  methods: {
    // 方法集合
    init() {
      let that = this;
      EventBus.$on("login", function () {
        //soket登录
        that.login();
      });
      EventBus.$on("upData", function (type, row) {
        //语音对讲
        // console.log(type, row);
        if (type == 0) {
          //摇开 摇闭
          ws.Audioenable([row.id], row.audioEnabled, function (ret) {
            console.log(ret);
          });
        } else if (type == 2) {
          //开启gps定位
          ws.SwitchLocation([row.id], row.GPSswitch, row.locPeriod, function (ret) {
            console.log(ret);
          });
        }
      });
      EventBus.$on("changePassword", function (type) {
        //修改密码
        if (type == 1) {
          ws.Logout();
          ws.DisNetConnect(token);
          //  that.disconnect();
          setTimeout(() => {
            // that.login();
            that.connect();
          }, 100)
        } else {
          if (that.$store.state.voiceCallInfo.callType > 0) {  //判断是否正在语音通话
            that.sendRespond(0, 0);
          }
          setTimeout(() => {
            ws.Logout();
            that.$router.push("/Login");
          }, 500)
        }
      });
      EventBus.$on("temporaryGroup", function (row) {
        //创建临时群组
        that.temporaryGroup(row);
      });
      EventBus.$on("menuOption", function (id, obj) {
        //强拉
        that.menuOption(id, obj);
      });
      EventBus.$on("speechType", function (type) {
        //讲话类型
        if (type == 1) {
          //1是讲话
          that.StartSpeak();
        } else {
          that.StopSpeak();
        }
      });
      EventBus.$on("pptJh", function (e) {
        //点击讲话
        if (e == 1) {
          if (that.$store.state.audio_enable == 1) {
            that.StartSpeak();
          } else {
            that.$MessageWarning(that.$t('message.message.Youhavebeenclosedforspeaking'));
          }
        } else {
          that.StopSpeak();
        }
      });
      EventBus.$on("getInto", function (data) {
        //进入群组
        that.getInto(data);
      });
      EventBus.$on("bindMonitorGroup", function (name, type, row) {
        //添加监听删除监听
        let groupUserData = that.$store.state.groupUserData;
        let f = groupUserData.findIndex(item => item.Gid == row.Gid);
        if (name == "monitor") {
          if (type == 1) {
            joinMonitorGroup({
              uId: that.$store.state.userInfo.User_ID,
              gId: row.Gid
            }).then(res => {
              if (res.data.code == 200) {
                that.$store.state.groupUserData[f].isMonitor = true;
                ws.StartWatchGroup(row.Gid, "1");
              } else {
                that.$MessageWarning(res.data.msg);
              }
            });
          } else {
            removeMonitorGroup({
              uId: that.$store.state.userInfo.User_ID,
              gId: row.Gid
            }).then(res => {
              if (res.data.code == 200) {
                that.$store.state.groupUserData[f].isMonitor = false;
                ws.StopWatchGroup(row.Gid);
              } else {
                that.$MessageWarning(res.data.msg);
              }
            });
          }
        }
      });
      EventBus.$on("strongBrushList", function () {    //强刷列表
        that.GetGroupsListRefresh();
      });
      EventBus.$on("radiobroadcast", function (type, ids, text) {    //广播功能
        that.radiobroadcastClose(type, ids, text);
      });
      EventBus.$on("startCall", function (ids) {    //发送呼叫
        that.startCall(ids);
      });
      EventBus.$on("voiceMute", function (type) {    //语音通话静音
        that.getPocSet(type);
      });
      EventBus.$on("sendRespond", function (type, sendType, tui) {    //发送回应
        if (tui == 1) {   //语音通话接听成功之后挂断则调用退组操作
          // let groupUserData = JSON.parse(JSON.stringify(that.$store.state.groupUserData));
          // let temporaryGroup = groupUserData.filter(item => item.GroupType == 2);   //判断当前群组列表是否有临时群组
          // if(temporaryGroup && temporaryGroup.length > 0){
          that.LeaveGroup();
          // }
        } else {
          that.sendRespond(type, sendType);
        }
      });
      EventBus.$on("StartSingleCallFullDuplex", function (ids) {    //开始全双工单呼
        that.StartSingleCallFullDuplex(ids);
      });

    },
    radiobroadcastClose(type, ids, text) {  //开始录制语音广播
      // let that = this;
      if (type == 1) {
        ws.StartRecordBroadcastAudio();
        // that.radiobroadcastTime();
      } else if (type == 2) {       //结束录制
        ws.StopRecordAudio(function (res) {
          console.log(res)
        })
      } else if (type == 3) {      //停止录制广播语音
        ws.StopRecordBroadcastAudio();
      } else if (type == 4) {      //播放录制语音
        ws.PlayRecordBroadcastAudio(function (res) {
          console.log(res)
        })
      } else if (type == 5) {      //发送语音广播
        ws.SendAudioBroadcast(ids);
      } else if (type == 6) {    //发送文字广播
        ws.SendTextBroadcast(text, ids);
      }
    },
    radiobroadcastTime() {   //记录录音时长
      if (this.$store.state.radiobroadcast.time != null) {
        clearInterval(this.$store.state.radiobroadcast.time);
        this.$store.state.radiobroadcast.time = null;
      }
      this.$store.state.radiobroadcast.time = setInterval(() => {
        if (this.$store.state.radiobroadcast.duration == 60) {
          ws.StopRecordBroadcastAudio(function (res) {
            console.log(res)
          })
          clearInterval(this.$store.state.radiobroadcast.time);
          this.$store.state.radiobroadcast.time = null;
          return
        }
        this.$store.state.radiobroadcast.duration += 0.5;
        console.log(this.$store.state.radiobroadcast.duration)
      }, 500)
    },
    temporaryGroup(row) {
      //创建临时群组
      let groupUserData = this.$store.state.groupUserData;
      let bool = groupUserData.some(
        item => item.GroupType == 2 && this.$store.state.soketUserInfo.gid == item.Gid
      );
      if (this.$store.state.audio_enable != 1) {
        this.$MessageWarning(this.$t('message.message.Shaketodeath'));
        return
      }
      if (bool) {
        this.$MessageWarning(this.$t('message.message.temporarygroupsnoadd'));
        return;
      } else {
        this.endTime();
      }
      if (row.length > 1) {
        let ids = row.map(item => item.id);
        ws.StartManyCall(ids);
        // this.startTime();
      } else {
        ws.StartSingleCall(row[0].id);
        // this.startTime();
      }
    },
    getQueryMonitorGroup() {
      //查询监听
      let that = this;
      let data = {
        uId: that.$store.state.userInfo.User_ID
      };
      let groupUserData = that.$store.state.groupUserData;
      queryMonitorGroup(data).then(res => {
        console.log(res);
        let list = res.data.data;
        if (list) {
          function into(i) {
            if (i < list.length) {
              let f = groupUserData.findIndex(item => item.Gid == list[i].gId);
              if (f >= 0) {
                that.$store.state.groupUserData[f].isMonitor = true;
              }
              into(i + 1);
            } else {
              sessionStorage.setItem(
                "groupArray",
                JSON.stringify(that.groupUserData)
              );
            }
          }
          into(0);
        }
      });
    },
    getInto(data) {
      //进入群组
      this.$store.state.soketUserInfo.gid = data.gid;
      this.JoinGroup(data.Gid);
      clearInterval(this.temporaryTime);
      this.temporaryTime = null;
      this.timeNum = 0;
    },
    endTime() {
      //结束计时
      clearInterval(this.temporaryTime);
      this.temporaryTime = null;
      this.timeNum = 0;
    },
    startTime: _throttle(function () {
      //开始计时
      let that = this;
      that.timeNum = 0;
      clearInterval(that.temporaryTime); //每次开始的时候清空计时
      if (that.temporaryTime === null) {
        function timerTow() {
          return setInterval(() => {
            that.timeNum++;
            if (that.timeNum == 60) {
              that.endTime();
              let groupUserData = JSON.parse(JSON.stringify(that.$store.state.groupUserData));
              let temporaryGroup = groupUserData.filter(item => item.GroupType == 2);   //判断当前群组列表是否有临时群组
              if (temporaryGroup && temporaryGroup.length > 0) {
                that.LeaveGroup();
              }
              that.isTemporaryGroup = 0;
            }
          }, 1000);
        }
        that.temporaryTime = timerTow();
        that.isTemporaryGroup = 1;
      }
    }, 1000),
    async menuOption(id, obj) {
      //呼叫 强拉  强拆
      if (id == 2) {
        //单呼组合临时群组
        this.temporaryGroup(obj.row);
      }
      if (id == 3) {
        //强拉
        let that = this;
        let groupUserData = that.$store.state.groupUserData;
        let lsGroup = groupUserData.find(
          itemOne => itemOne.Gid == that.$store.state.soketUserInfo.gid
        ); //判断是否是临时组
        // let members = [];
        let ids = [];
        let array = groupUserData.find(item => item.Gid == that.$store.state.soketUserInfo.gid);
        let bool = false;
        await obj.ids.forEach(item => {
          let isTrue = array.children.some(item1 => item1.id == item);
          if (isTrue) {
            ids.push(item);
          } else {
            bool = true
          }
        });
        // if(members.length <= 0){    //如果用户都没有进组那就直接拉
        //   ws.TempEnterGroup(that.$store.state.soketUserInfo.gid, obj.ids);
        //   return
        // }
        if (bool) {
          that.$MessageWarning(that.$t('message.message.haleDispatcherGroupUser'));
          return
        }
        if (lsGroup && lsGroup.GroupType != 2) {
          ws.TempEnterGroup(that.$store.state.soketUserInfo.gid, obj.ids);
        } else {
          that.$MessageWarning(that.$t('message.message.TemporaryGroupNoHale'));
        }
      }
      if (id == 4) {
        //强拆
        ws.ForceDemolition(obj.ids);
      }
    },
    // 以下是webSocket方法
    netstate(state) {
      //WebSocket 状态回掉回调消息
      if (state == "open") {
        this.WriteIni();
        this.$store.state.isLinkSuccess = true; //是否链接成功
        if (checknet) {
          checknet = false;
          // false
          ws.Reconnect(true);
          ws.CallMessage(this.message);
        }
        this.connect(0);
        // if (this.loginState == 1) {
        //   this.restoration();
        // } else {
        //   this.connect(1);
        // }
        this.$store.state.userSpeech.isShow = false;
        this.$store.state.userSpeech.isBool = false;
      }
      if (state == "error") {
        this.$store.state.isLinkSuccess = false; //链接失败
        if (checknet) {
          console.info("无法链接请下载安装包");
          // that.$MessageError('无法链接，请刷新浏览器重连！')
        }
      }
    },
    message(name, msg) {
      //异步通知回掉消息
      console.log(name, msg);
      if (name == "ui.act.LoginAck") {
        if (msg.getResult() == -12) {
          console.info("请先点击链接");
        }
      }
      if (name == "ui.act.SetCustomAck") {
        if (msg.getResult() == 0) {
          console.info("修改配置成功");
        }
      }
      if (name == "ui.act.GetCustomAck") {
        if (msg.getResult() == 0) {
          console.info(
            "DNS:" + msg["array"][1] + " context:" + msg["array"][2]
          );
        }
      }
      if (name == "ui.push.OnlineStatusChanged") {
        //登录状态改变通知
        this.onOnlineStatusChangedX(msg.getStatus());
      }
      if (name == "ui.push.GroupListChanged") {
        //群组发生变化通知
        var a = msg["array"][0];
        this.onGroupListX(JSON.stringify(a));
      }
      if (name == "ui.push.AudioEnableChanged") {
        //摇开回调
        this.onAudioEnableChangedX(msg.getIsaudioenable());
      }
      if (name == "ui.push.AudioEnableResult") {
        console.log(msg);
        // this.onAudioEnabledX(JSON.stringify(msg["array"]));
      }
      if (name == "ui.push.ExternalServMsg") {
        //sos推送
        this.getExternalServMsg(msg["array"][0])
      }
      if (name == "ui.push.ChangeNameResult") {
        //修改名字回调
        this.onChangeNameResultX(msg["array"][0]);
      }
      if (name == "ui.push.ChangePasswordResult") {
        //修改密码通知
        this.onChangePasswordResultX(msg["array"][0]);
      }
      if (name == "ui.push.DispatchResult") {
        //将用户拉到指定组
        console.log("强拉");
        // this.onDispatchedX(JSON.stringify(msg["array"]));
      }
      if (name == "ui.act.StartSingleCallAck") {
        //单呼成功回调
        // console.log("单呼");
        if (msg.array[0] == 0) {
          // EventBus.$emit('successMsg',msg.array[0]);
          this.startTime();
        } else {
          this.endTime();
        }
      }
      // if (name == "ui.act.ManyCallAck") {       //组呼成功
      //   //组呼回调
      //   // console.log("组呼");
      //   if(msg.array[0] == 0){
      //     // EventBus.$emit('successMsg',msg.array[0]);
      //     this.startTime();
      //   }else{
      //     this.$MessageError('组呼失败')
      //     this.endTime();
      //   }
      // }
      if (name == "ui.push.CallAck") {
        if (msg.array[0] == 1) {
          // EventBus.$emit('successMsg',msg.array[0]);
          this.startTime();
        } else {
          this.$MessageError(this.$t('message.message.Groupcallfailure'));
          this.endTime();
        }
      }
      if (name == "ui.push.TakemicResult") {
        //强拆回调
        console.log("强拆", msg);
        // this.onTakeMicX(JSON.stringify(msg["array"]));
      }
      if (name == "ui.push.SwitchLocateResult") {
        console.log(JSON.stringify(msg));
        // this.onSwitchLocationX(JSON.stringify(msg));
      }
      // if (name == "ui.push.PostBroadcastResult") {
      //     this.onSendedX(msg["array"][0]);
      // }
      if (name == "ui.push.CurrentGroupChanged") {
        //当前所在群组变化通知
        this.onCurrentGroupX(msg["array"][0], msg["array"][1]);
      }
      if (name == "ui.push.UsersChanged") {
        //用户状态变化通知
        this.onUserChangedX(msg["array"][0], msg["array"][1]);
      }
      if (name == "ui.push.UserStartSpeak") {
        //讲话者开始讲话事件通知
        this.onStartPlayingX(
          msg["array"][1],
          msg["array"][3],
          msg["array"][0],
          msg["array"][2]
        );
      }
      if (name == "ui.push.UserStopSpeak") {
        //讲话者停止讲话事件通知
        this.onStopPlayingX(
          msg["array"][1],
          msg["array"][3],
          msg["array"][0],
          msg["array"][2]
        );
      }
      if (name == "ui.push.MemberChanged") {
        //群组成员发生变化通知
        this.onMemberChangedX(msg["array"]);
      }
      if (name == "ui.push.PlayAudioFileStatue") {
        //结束播放录音的通知事件
        this.onStopPalyRecordX(msg["array"][0]);
      }
      // if (name == "ui.push.RecordStart") {
      //     this.onStartRecordX(JSON.stringify(msg["array"]));
      // }
      // if (name == "ui.push.RecordStop") {
      //     this.onStopRecordX(JSON.stringify(msg["array"]));
      // }
      if (name == "ui.push.ErrorMessage") {
        // console.log(msg);
        this.onErrorX(msg["array"][0]);
      }
      // if (name == "ui.act.StartSpeakAck") {
      //   // if(msg["array"][0] != 0){
      //   //   
      //   // }
      // }
      if (name == "ui.push.SelfStartSpeak") {
        //开始讲话事件通知
        this.onStartSpeakX(msg["array"][0], msg["array"][2]);
      }
      if (name == "ui.push.SelfStopSpeak") {
        //停止讲话事件通知
        this.onStopSpeakX(msg["array"][0], msg["array"][2]);
      }
      if (name == "ui.push.MemberListChanged") {
        //群组列表改变回调
        this.GetGroupList();
      }

      if (name == "ui.push.RecordBroadcastStatus") {
        if (msg.array[0] == 0) {    //0是暂停录制 1是开始
          this.$store.state.radiobroadcast.url = msg.array[1];
        }
      }

      if (name == "ui.push.ServerInfoMsg") {
        let array = msg.array[0];
        let ip;
        array.forEach(item => {
          if (item[2] == "nginx") {
            ip = `${item[0]}:${item[1]}`;
            // ip.push({
            //   http: item[0],
            //   port: item[1],
            //   name: item[2]
            // });
          }
        });
        sessionStorage.setItem("ngixUrl", ip);
      }

      if (name == "ui.push.InviteAck") {
        this.onInviteAckMsgX(JSON.stringify(msg["array"]))
      }

      if (name == "ui.push.InviteAckTimeOut") {
        this.onInviteAckIiemOutMsgX(JSON.stringify(msg["array"]))
      }

      if (name == "ui.push.ResponseInviteAckTimeOut") {
        this.onResponseInviteAcIiemOutkMsgX(JSON.stringify(msg["array"]))
      }
      if (name == "ui.push.ResponseInviteAck") {
        this.onResponseInviteAckMsgX(JSON.stringify(msg["array"]))
      }
      if (name == "ui.push.PushInvite") {
        this.onPushInviteMsgX(JSON.stringify(msg["array"]))
      }
      if (name == "ui.push.PushResponseInvite") {
        this.onPushResponseInviteMsgX(JSON.stringify(msg["array"]))
      }
    },
    //链接服务
    connect(type) {
      var appid = "0123";
      var password = "0123";
      var type = "1";
      var version = "1.0";
      let that = this;
      ws.NetConnect(appid, password, type, version, function (ret) {
        if (ret.getResult() == 0) {
          token = ret.getToken();
          console.info("链接成功");
          that.$store.state.isLinkSuccess = true;
          that.restoration(0);
        } else {
          that.$store.state.isLinkSuccess = false;
          that.connect(0);  //重新链接
          console.info("链接失败");
        }
      });
    },

    //断开服务
    disconnect() {
      ws.DisNetConnect(token);
      this.connect(0);  //重新链接
    },

    //发送Log消息
    login() {
      let userNumber = JSON.parse(sessionStorage.getItem("userNumber"));
      var account = userNumber.userName;
      var password = "1";
      var role = 0;
      // let that = this;
      // ws.Restoration(account, function(ret) {
      //     if (ret.getResult() == 0) {   //复位成功是代表已经登录过了
      //         that.loginState = 1;
      //         that.$router.push("./dashboard");
      //         console.info("复位成功");
      //         that.GetGroupsListRefresh();
      //         that.GetCurrentUser();  
      //         that.GetGroupList(0);
      //     } else {
      // that.loginState = 0;
      // if(that.loginState == 0){
      ws.Login(token, account, password, role);
      // }else{
      //     //   that.$router.push("./dashboard");
      //     //   that.GetCurrentUser();  
      //     //   that.GetGroupList();
      //     // }
      //   }
      // });
    },

    //发送复位消息
    restoration(type) {
      let userNumber = JSON.parse(localStorage.getItem("userNumber"));
      let userNumber1 = JSON.parse(sessionStorage.getItem("userNumber"));
      let that = this;
      var account = 1;
      if (userNumber1) {
        account = userNumber.userName;
      }
      ws.Restoration(account, function (ret) {
        that.endTime();
        if (ret.getResult() == 0) {   //复位成功是代表已经登录过了
          if (type === 0) {
            that.loginState = 1;
            console.info("复位成功");
            that.GetCurrentUser();
            that.GetGroupList();
          }
        } else {
          that.loginState = 0;
          console.info("复位失败");
          if (
            JSON.stringify(that.$store.state.userInfo) != "{}" &&
            that.$route.name != "Login"
          ) {
            //判断当前对象是否为空
            that.login();
          }
        }
      });
    },

    //发送退出登录消息
    Logout(type) {
      let that = this;
      // localStorage.removeItem("userNumber");
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("navId");
      that.$router.push("/Login");
      if (type == 0) {
        that.$message({
          type: "success",
          message: this.$t('message.message.Exitsuccessfully')
        });
      }
      ws.Logout();
      // this.loginState = 0;
      // that.disconnect();    //断开链接
    },
    playGb() {   //播放广播
      this.$store.state.tipsVoice = new Audio(tipsVoice);
      this.$store.state.tipsVoice.pause();    //先暂停在播放防止没有声音
      if (this.$store.state.voiceCallInfo.callType != 0) {

        return
      }
      if (this.$store.state.videoCallInfo.callType != 0) {

        return
      }
      if (this.$store.state.gbInfoVoice.length <= 0 && this.$store.state.gbInfoText.length <= 0) {
        // msg.text = '收到广播';
        // synth.cancel(msg);
        setTimeout(() => {
          this.$nextTick(() => {
            this.$store.state.tipsVoice.play();
          })
        }, 2000)
      } else {
        this.$nextTick(() => {
          this.$store.state.tipsVoice.play();
        })
      }
      // window.speechSynthesis.cancel()
      msg.text = '收到广播'; // 文字内容: 小朋友，你是否有很多问号
      msg.lang = "zh-CN"; // 使用的语言:中文
      msg.volume = 0; // 声音音量：1
      msg.rate = 1; // 语速：1
      msg.pitch = 1; // 音高：1
      synth.speak(msg); // 播放
    },

    /*--------------------------------------------配置信息-------------------------*/

    //设置信息
    WriteIni() {
      let httpXin = beasconfig.httpXin;
      let typeName = beasconfig.typeName;
      ws.setCustom(httpXin, typeName, 1);  //1是 国外 0国内
    },

    //读取配置信息
    ReadIni() {
      ws.getCustom();
    },
    /*--------------------------------------------语音通话全双工-------------------------*/
    hangUpVoice() {
      let fullduplex = sessionStorage.getItem("fullduplex");
      let groupArray = sessionStorage.getItem("groupArray");
      if (fullduplex) {   //判断语音通话中刷新浏览器自动挂断语音通话
        this.fullduplex = JSON.parse(fullduplex);
        if (this.fullduplex.isAnswer != true) {
          this.sendRespond(0, 0);
        }
        if (groupArray && this.fullduplex.isAnswer == true) {
          groupArray = JSON.parse(groupArray);
          let temporaryGroup = groupArray.filter(item => item.GroupType == 2);
          if (temporaryGroup && temporaryGroup.length > 0) {
            temporaryGroup.forEach(item => {
              if (item.GroupType == 2 && this.$store.state.soketUserInfo.gid == item.Gid) {
                //判断是否在当前临时群组
                this.GetGroupByGid(item.Gid, 2);
              }
            });
          }
        }
      }
    },
    startCall(ids) { //发送呼叫
      EventBus.$emit("openPhoneCall", 0);
      ws.Invite(ids, 1, 0);
    },
    sendRespond(type, sendType) { //发送回应
      var account = this.fullduplex.account;
      var uid = this.fullduplex.uid;
      ws.ResponseInvite(account, uid, type, sendType);  //type 1接收  0拒绝   //sendType 0拒绝  1接听  2正忙  4 未接通
      if (type == 1) {
        this.fullduplex.isAnswer = true;
        sessionStorage.setItem("fullduplex", JSON.stringify(this.fullduplex));
      }
    },
    StartSingleCallFullDuplex(ids) {
      // var uid = 20209;
      EventBus.$emit('answerVoice');
      ws.StartSingleCallFullDuplex(ids);
      this.fullduplex.isAnswer = true;
      sessionStorage.setItem("fullduplex", JSON.stringify(this.fullduplex));
    },
    //发送邀请是否成功回应
    onInviteAckMsgX(msg) {
      msg = JSON.parse(msg);
      if (msg[0] == 0) {
        this.$store.state.voiceCallInfo.callType = 1;
        this.fullduplex.account = msg[1];
        this.fullduplex.uid = msg[2][0];
        sessionStorage.setItem("fullduplex", JSON.stringify(this.fullduplex));
      } else {
        if (msg[4] == 1) {    //0-不支持 1-不在线 2-会话中 3-免打扰
          this.closeVoice(this.$t('message.message.Usernotonline'), 0)
        } else if (msg[4] == 2) {
          this.closeVoice(this.$t('message.message.Theotherpartyisbusy'), 0)
        } else {
          this.closeVoice(this.$t('message.message.Callfailed'), 0)
        }
      }
      console.info(" onInviteAckMsgX " + msg);
    },
    //发送邀请,服务器回复超时
    onInviteAckIiemOutMsgX(msg) {
      console.info(" onInviteAckIiemOutMsgX " + msg);
    },

    //发送回应，服务器回复超时
    onResponseInviteAcIiemOutkMsgX(msg) {
      console.info(" onResponseInviteAcIiemOutkMsgX " + msg);
    },
    //发送回应是否成功回应
    onResponseInviteAckMsgX(msg) {
      console.info(" onResponseInviteAckMsgX " + msg);
    },

    //收到邀请
    onPushInviteMsgX(msg) {
      msg = JSON.parse(msg);
      let groupUserData = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
      let temporaryGroup = groupUserData.filter(item => item.GroupType == 2);   //判断当前群组列表是否有临时群组
      let temporaryGroupTow = temporaryGroup.some(item => this.$store.state.soketUserInfo.gid == item.Gid);
      if (this.$store.state.userState.fullDuplex == 0) {
        ws.ResponseInvite(msg[0], msg[1], 0, 5);
      } else {
        if (temporaryGroup && temporaryGroup.length > 0 && temporaryGroupTow) {
          ws.ResponseInvite(msg[0], msg[1], 0, 2);
        } else {
          if (this.$store.state.voiceCallInfo.callType <= 0 && this.$store.state.videoCallInfo.callType <= 0) {
            let fd = this.$store.state.groupOnline[0].onlineArray.find(item => item.id == msg[1]);
            if (fd) {
              this.$store.state.voiceCallInfo.callName = fd.label;
            }
            this.fullduplex.account = msg[0];
            this.fullduplex.uid = msg[1];
            this.fullduplex.accept = msg[2];
            this.fullduplex.with_video = msg[3];
            this.$store.state.voiceCallInfo.callType = 2;
            sessionStorage.setItem("fullduplex", JSON.stringify(this.fullduplex));
            EventBus.$emit("openPhoneCall", 1);
          } else {
            ws.ResponseInvite(msg[0], msg[1], 0, 2);
          }
        }
      }
    },
    //收到回应
    onPushResponseInviteMsgX(msg) {
      msg = JSON.parse(msg);
      if (this.fullduplex['uid'] && this.fullduplex['uid'] == msg[1]) {  //判断用户id是否和正在通话的用户id一样
        if (msg[3] == 0) {
          this.closeVoice(this.$t('message.message.Theotherpartyhashungup'), 0);
        } else if (msg[3] == 1) {    //接听
          this.StartSingleCallFullDuplex(msg[1]);
        } else if (msg[3] == 2) {
          this.closeVoice(this.$t('message.message.Theotherpartyisbusy'), 0);
        } else if (msg[3] == 4) {
          this.closeVoice(this.$t('message.message.Theotherpartyhashungup'), 0);
        }
      }
    },
    closeVoice(title, type) {
      this.$MessageWarning(title);
      if (type == 0) {
        setTimeout(() => {
          EventBus.$emit('closeConversation', 0);
        }, 2000)
      } else {
        EventBus.$emit('closeConversation', 1);
      }
    },
    /*--------------------------------------------账号信息-------------------------*/

    getExternalServMsg(msg) {    //透传
      let sosInfo = JSON.parse(msg);
      EventBus.$emit("sosTips",sosInfo["serviceType"]);
      if (
        (sosInfo["type"] == 0 &&
          sosInfo["serviceType"] == "sosTranssparentType") ||
        (sosInfo["type"] == "" &&
          sosInfo["serviceType"] == "sosTranssparentType")
      ) {
        //sos推送
        this.$store.state.calltThePoliceType = 0;
        sosInfo.sosContent.sosStatus = 0;
        let sos = {
          sendName: sosInfo.sosContent.sosSendName,
          sos_start_time: this.$moment(sosInfo.sosContent.sosStamp).format("YYYY-MM-DD HH:mm:ss"),
          sos_msg_id: sosInfo.sosContent.sosMsgId,
          sos_from_id: sosInfo.sosContent.sosFromId,
          Cg_Name: sosInfo.sosContent.cgName,
          Rtimestamp: this.$moment().format("YYYY-MM-DD HH:mm:ss")
        }
        if (sosInfo.sosContent.sosLocationAt) {
          sosInfo.sosContent.sosLocationAt = JSON.parse(sosInfo.sosContent.sosLocationAt);
          sos.pot = {
            Lng: sosInfo.sosContent.sosLocationAt.wgs84.lon,
            Lat: sosInfo.sosContent.sosLocationAt.wgs84.lat
          };
          // sosInfo.sosContent.sosLocationAt.address = `${Point.lat},${Point.lng}`;
          this.$store.state.sosPushInfo = [sos];
          // this.$store.state.sosPushInfo.push(sos);
        } else {
          this.$store.state.sosPushInfo = [sos];
        }
        if (this.$store.state.mapMarkerIndex == 1) {
          this.$store.state.mapMarkerIndex = 0;
        }
        this.$store.state.sosCallthepolice = true;
        this.StopSpeak();
      } else if (
        sosInfo["type"] != 0 &&
        sosInfo["serviceType"] == "sosTranssparentTypeEx"
      ) {
        let sosMsgId = sosInfo.sosContent.sosMsgId;
        EventBus.$emit("cloneSosInfo", sosMsgId);
        EventBus.$emit("handleSosMarker", sosMsgId);
        this.$store.state.handleData.push(sosInfo);
      }
      if (sosInfo["serviceType"] == "reportType") {   //广播
        // this.StopSpeak();
        // if(this.$store.state.gbInfoText.length <= 0 && this.$store.state.gbInfoVoice.length <= 0){
        this.playGb();
        // }
        if (this.$store.state.gbInfoVoice.length <= 0 && this.$store.state.gbInfoText.length <= 0) {
          setTimeout(() => {
            if (sosInfo.data.msg_type == 3) {
              this.$store.state.gbInfoVoice.unshift(sosInfo.data);
            } else {
              this.$store.state.gbInfoText.unshift(sosInfo.data);
            }
          }, 3000)
        } else {
          setTimeout(() => {
            if (sosInfo.data.msg_type == 3) {
              this.$store.state.gbInfoVoice.unshift(sosInfo.data);
            } else {
              this.$store.state.gbInfoText.unshift(sosInfo.data);
            }
          }, 1000)
        }
      }
      // && sosInfo.type == "1"
      if (sosInfo["serviceType"] == "enclosure") {   //围栏警告
        // this.StopSpeak();
        if (sosInfo.type == "1") {
          this.$store.state.calltThePoliceType = 0;
          let find = this.$store.state.groupOnline[0].onlineArray.find(item1 => sosInfo.Info.UID == item1.id);
          if (find) {
            sosInfo.Info.userName = find.label;
            this.$store.state.enclosureTc.push(sosInfo);
          }
        }
      }
      if (sosInfo["serviceType"] == "rtcServiceType") {   //视频透传
        let rtcData = sosInfo.rtcData ? JSON.parse(sosInfo.rtcData) : null;
        let videoList = this.$store.state.videoCallInfo.forcedpullList;
        if (sosInfo.rtcType == 200) {   //呼叫
          if (this.$store.state.videoCallInfo.callType <= 0 && this.$store.state.voiceCallInfo.callType <= 0) {    //判断是否已经接听了视频通话,语音通话
            this.$store.state.videoCallInfo.userId = sosInfo.rtcFrom;
            this.$store.state.videoCallInfo.ownId = sosInfo.rtcTo;
            this.$store.state.videoCallInfo.callName = sosInfo.rtcExtra.fromUserName;
            this.$store.state.videoCallInfo.videoPull = global.replaceParamVal(rtcData.pull_web, 'token', this.$store.state.videoToken);
            this.$store.state.videoCallInfo.videoPush = global.replaceParamVal(rtcData.push_web, 'token', this.$store.state.videoToken);
            this.$store.state.videoCallInfo.recordId = sosInfo.rtcExtra.recordId;
            EventBus.$emit("agreeVideoCall", 200);
            this.$store.state.videoCallInfo.callType = 4;
          } else {
            EventBus.$emit("agreeVideoCall", 304, sosInfo.rtcFrom);
          }
        }
        if (sosInfo.rtcType == 201 || sosInfo.rtcType == 101) {   //回传 101 强拉  201回传
          let b = videoList.some(item => item.userId == sosInfo.rtcFrom && item.isShow);
          if (!b) {
            // videoType 201 回传
            // let isMute = this.$store.state.videoCallInfo.isMute;  //判断实时视频页面是否静音
            let videoData = { name: sosInfo.rtcExtra.fromUserName, url: rtcData.pull_web, userId: sosInfo.rtcFrom, inviteTime: sosInfo.rtcExtra.inviteTime, isMute: false, videoType: sosInfo.rtcType, isShow: true, videoId: 'video' + sosInfo.rtcExtra.recordId + Math.floor(Math.random() * 10) + 1 };
            if (sosInfo.rtcType == 201) {
              this.$store.state.videoCallInfo.echoList.push(videoData);
            } else {
              this.$store.state.videoCallInfo.forcedpullEchoList.push(videoData);
            }
            this.$store.state.videoCallInfo.forcedpullList.push(videoData);
            sessionStorage.setItem('videoList', JSON.stringify(this.$store.state.videoCallInfo.forcedpullList));
          }
        }
        if (sosInfo.rtcType == 202 || sosInfo.rtcType == "video.StreamStop") {   //回传结束
          let dleId = sosInfo.rtcType == "video.StreamStop" ? sosInfo.rtcArrayData[0].value : sosInfo.rtcFrom;
          let index = videoList.findIndex(item => item.userId == dleId);
          let echoListIndex = this.$store.state.videoCallInfo.echoList.findIndex(item => item.userId == dleId);
          if (videoList.length > 0 && index >= 0) {
            // setTimeout(()=>{
            if (this.$store.state.videoCallInfo.switchVideo == 1) {   //判断页面不在
              EventBus.$emit("deleteVideo", dleId);
            } else {
              if (this.$store.state.videoCallInfo.forcedpullList.length > 0) {
                this.$store.state.videoCallInfo.forcedpullList.splice(index, 1);
                sessionStorage.setItem('videoList', JSON.stringify(this.$store.state.videoCallInfo.forcedpullList));
              }
            }
            // },100)
          }
          if (this.$store.state.videoCallInfo.echoList.length > 0) {
            this.$store.state.videoCallInfo.echoList.splice(echoListIndex, 1);
          }
        }
        if (sosInfo.rtcType == 300) {   //300接受
          this.$store.state.videoCallInfo.callType = 1;
          EventBus.$emit("agreeVideoCall", 300);
        }
        if (sosInfo.rtcType == 301) {   //301 拒绝
          EventBus.$emit("gdVideoCall", 301);
        }
        if (sosInfo.rtcType == 302) {   //结束
          EventBus.$emit("gdVideoCall", 302);
        }
        if (sosInfo.rtcType == 303) {   //未接
          EventBus.$emit("gdVideoCall", 303);
        }
        if (sosInfo.rtcType == 304) {   //对方正忙
          EventBus.$emit("gdVideoCall", 304);
        }
      }
      if (sosInfo["serviceType"] == "userMsg") {   //用户透传
        if (this.$store.state.userInfo.User_ID == sosInfo.msg[0].user_id) {   //判断透传是否是当前调度员
          if (sosInfo.msg[0].user_type == 0) {    //判断透传身份是否是调度员
            this.Logout();
          }
          this.$store.state.userState.fullDuplex = sosInfo.msg[0].full_duplex;
          this.$store.state.userService.video_call = sosInfo.msg[0].video_call;
          this.$store.state.userService.services.video = sosInfo.msg[0].services.video;
          // this.$store.state.userState.services = sosInfo.msg[0].services;
        }
        let openArray = JSON.parse(sessionStorage.getItem("openArray"));
        if (this.$store.state.navId == 1 && openArray || this.$store.state.navId == 3 && openArray) {
          EventBus.$emit("updateData", JSON.stringify(sosInfo.msg[0]), 'userMsg');
        }
      }
      if (sosInfo["serviceType"] == "monitorMsg") {   //监听群组移除通知
        let groupUserData = this.$store.state.groupUserData.filter(item => item.isMonitor);
        if (groupUserData.length < sosInfo.msg.length) {  //当前监听群组小于平台透传监听  添加
          sosInfo.msg.forEach(item => {
            var bool = groupUserData.some(row => row.Gid == item);
            if (!bool) {
              ws.StartWatchGroup(item, "1");
            }
          })
        }
        if (groupUserData.length > sosInfo.msg.length) {    //当前监听群组大于平台透传监听  删除
          groupUserData.forEach(item => {
            var bool = sosInfo.msg.some(row => row == item.Gid);
            if (!bool) {
              ws.StopWatchGroup(item.Gid);
            }
          })
        }
        EventBus.$emit("updateData", JSON.stringify(sosInfo.msg), 'monitorMsg');
      }

      if (sosInfo["serviceType"] == "closeDispatchMsg") {  //调度员角色是否被关闭
        this.Logout();
      }
      console.log(sosInfo, 1111111);
    },
    //获取当前用户信息
    GetCurrentUser() {
      let that = this;
      ws.GetCurrentUser(function (ret) {
        // console.log(ret);
        let array = ret.array;
        // that.$store.state.userInfo.nickname = array[1][1];
        that.soketUserInfo = {
          uid: array[1][0],
          name: array[1][1],
          label: array[1][1],
          online: array[1][2],
          audioEnabled: array[1][3],
          sleep: null,
          Dnd: null,
          has_gid: array[1][6],
          gid: array[1][7],
          role: array[1][8],
          GPSswitch: array[1][9],
          locPeriod: array[1][10]
        };
        that.$store.state.soketUserInfo = that.soketUserInfo;
        that.hangUpVoice();
        // console.info(JSON.stringify(ret["array"]));
        // console.info("===============")
      });
    },
    //获取当前在线状态
    GetOnlineStatus() {
      ws.GetCurrentOnlineStatus(function (ret) {
        console.info("=======GetOnlineStatus ret========");
        console.info(JSON.stringify(ret["array"]));
        console.info("===============");
      });
    },

    //获取当前账号UID消息
    GetUid() {
      ws.GetCurrentUid(function (ret) {
        console.info("=======GetUid ret========");
        console.info(JSON.stringify(ret["array"]));
        console.info("===============");
      });
    },

    //获取当前用户名消息
    GetName() {
      ws.GetCurrentName(function (ret) {
        console.info("=======GetName ret========");
        console.info(JSON.stringify(ret["array"]));
        console.info("===============");
      });
    },

    //获取当前用户是否被禁言
    HasAudioEnabled() {
      ws.HasAudioEnabled(function (ret) {
        console.info("=======HasAudioEnabled ret========");
        console.info(JSON.stringify(ret["array"]));
        console.info("===============");
      });
    },
    //向qt发送修改用户名
    ChangeName() {
      ws.GetChangeName("130326182871");
    },

    //向qt发送修改密码
    ChangePassword() {
      ws.GetChangePassword("aa28830963", "aa288309631");
    },

    /*--------------------------------------------群组信息-------------------------*/
    //入到相应的群组里
    JoinGroup(gid) {
      // var gid = "1204118789933771885";
      ws.EnterGroup(gid);
      this.isTemporaryGroup = 0;
    },
    //离开群组
    LeaveGroup(Gid) {
      // console.log('退出群组11111111111111111111111111111')
      ws.LeaveGroup();
      this.isTemporaryGroup = 0;
      if (Gid) {
        this.GetGroupByGid(Gid, 1);
      }
    },

    //根据GID 查询群组信息
    GetGroupByGid(id, type) {
      var gid = id;
      ws.GetGroupByGid(gid, function (ret) {
        console.log(ret)
        let num = ret['array'][1][4];
        if (num == 1 && type == 1) {  //1：双工 0 正常群组
          EventBus.$emit('closeConversation');
        } else if (num == 1 && type == 2) {
          this.LeaveGroup();
        }
      });
    },
    GetGroupsListRefresh() {   //强制刷新群组列表
      ws.GetGroupsListRefresh(true, function () {
        // console.log(ret)
      })
    },
    GetGroupList: _throttle(function () {
      //获取群组列表
      //节流
      let that = this;
      if (that.loginState == 0) { //初次登录调用强刷
        that.GetGroupsListRefresh();
        that.loginState = 1;
      }
      queryMonitorGroup({
        //查询是否有监听
        uId: that.$store.state.userInfo.User_ID
      }).then(row => {
        // console.log(row);
        let list = row.data.data;
        ws.GetGroupsList(function (ret) {
          let data = ret.array;
          if (data[0] == 0) {
            let groupUserData = [];
            let regionGroup = [];
            let seltGroup = [];
            let groupOnline = [
              {
                GroupType: [],
                onlineArray: []
              }
            ]; //群组在线用户
            let array = data[1];
            into(0);
            async function into(n) {
              if (n < array.length) {
                let listIndex = list
                  ? list.findIndex(item => array[n][0] == item.gId)
                  : -1; //判断当前群组是否监听
                await ws.GetGroupMembers(array[n][0], false, function (res) {
                  let GroupUser = res.array[4];
                  let groupArray = [];
                  let user1 = []; //在线再组调度员
                  let user2 = []; //在线不再组普通成员
                  let user3 = []; //在线调度员
                  let user4 = []; //在线普通成员
                  let user5 = []; //离线调度员
                  let user6 = []; //离线普通成员
                  // if(GroupUser){
                  if (GroupUser) {
                    GroupUser.forEach(item => {
                      let userData = {
                        MemberTota: res.array[2],
                        id: item[0][0],
                        label: item[0][1],
                        online: item[0][2], //在线 3  不在线 0,
                        disabled: item[0][2] != 3,
                        audioEnabled: item[0][3], //是否开启语音对讲  true  flase
                        sleep: null,
                        dnd: null,
                        has_gid: item[0][6],
                        gid: array[n][0],  //item[0][7]
                        role: item[0][8],
                        GPSswitch: item[0][9], //gps 定位
                        locPeriod: item[0][10], //定位频率
                        isBool: true,
                        isOpen: false,
                        Ingroup: item[1], //是否在组
                        priority: item[2], //优先级0
                        audioenable: item[3],
                        key: array[n][0] + "" + item[0][0],
                        mmcount: item[0][0] == array[n][3] ? array[n][3] : 0,  //是否是自建组群组  0正常
                        isStartSpeech: false //语音是否通话
                      }
                      if (userData.online == 3 && userData.role == 3 && userData.Ingroup) {   //在线再组调度员
                        user1.push(userData);
                      } else if (userData.online == 3 && userData.role == 0 && userData.Ingroup) { //在线不再组普通成员
                        user2.push(userData);
                      } else if (userData.online == 3 && userData.role == 3) { //在线调度员
                        user3.push(userData);
                      } else if (userData.online == 3 && userData.role == 0) { //在线普通成员
                        user4.push(userData);
                      } else if (userData.online != 3 && userData.role == 3) { //离线调度员
                        user5.push(userData);
                      } else if (userData.online != 3 && userData.role == 0) { //离线普通成员
                        user6.push(userData);
                      }
                      groupOnline[0].onlineArray.push(userData);
                      // groupArray.push(userData);
                    });
                    groupArray = groupArray.concat(user1, user2, user3, user4, user5, user6);
                    groupOnline[0].GroupType.push({
                      //群组在线
                      GroupType: array[n][2]
                    });
                    let groupData = {
                      id: array[n][0], //群组 ID
                      label: array[n][1],
                      isShow: true,
                      isMonitor: listIndex >= 0 ? true : false, //监听
                      // isBool: false,
                      disabled: groupArray.filter(item => item.online == 3).length <= 0 ? true : false,
                      isRadioBroadcast: false, //选中广播
                      Gid: array[n][0],
                      Gname: array[n][1], //群组名称
                      GroupType: array[n][2], //群组类型 1：为长期群组 2：为临时群组
                      mmcount: array[n][3],
                      mmingroup: array[n][4],  //1双工  0正常
                      num: groupArray.filter(item => item.online == 3).length,
                      total: groupArray.length,
                      userState:
                        that.$store.state.soketUserInfo.gid == array[n][0] ? true : false, //用戶所在群組
                      children: groupArray,
                      key: array[n][0]
                    }
                    if (groupArray.length <= 0) {
                      groupData.isBool = true;
                    }
                    if (groupData.mmcount > 0 || groupData.userState) { //自建组和当前组进入这里
                      if (groupData.userState) {
                        seltGroup.unshift(groupData);
                      } else {
                        seltGroup.push(groupData);
                      }
                    } else if (array[n][2] == 2) {
                      //临时群组
                      that.GetGroupByGid(array[n][0]);
                      if (groupData.total > 0) {    //判断临时群组是否有用户
                        groupUserData.unshift(groupData);
                      }
                    } else if (array[n][2] == 4) {
                      let qy = JSON.parse(sessionStorage.getItem("regionGroup"));   //判断是否有删除的区域群组
                      if (qy) {
                        let find = qy.find(item => item == groupData.Gid);
                        if (!find) {
                          regionGroup.push(groupData);
                        }
                      } else {
                        regionGroup.push(groupData);
                      }
                    } else {
                      //固定群组
                      groupUserData.push(groupData);
                    }
                    into(n + 1);
                  }
                });
              } else {
                that.$store.state.recordAddRegionNum = regionGroup.length;
                groupUserData.unshift(...seltGroup);  //自建组在固定群组前面
                that.$store.state.groupUserData = groupUserData.concat(regionGroup);
                // that.groupUserData = groupUserData.concat(regionGroup);
                groupOnline[0].groupListUser = groupOnline[0].onlineArray;    //群组所有用户未去重
                groupOnline[0].onlineArray = that.deweight(       //群组所有用户去重
                  groupOnline[0].onlineArray
                );
                sessionStorage.setItem(
                  "groupArray",
                  JSON.stringify(that.$store.state.groupUserData)
                );
                that.$store.state.groupOnline = groupOnline;
                that.handleNodeExpand();
                // that.getQueryMonitorGroup();
                that.signoutGroup();
                // that.hangUpVoice();
              }
            }
          }
        });
      });
    }, 1000),
    signoutGroup() {
      //退出群组
      let that = this;
      let groupUserData = JSON.parse(
        JSON.stringify(that.$store.state.groupUserData)
      );
      let temporaryGroup = groupUserData.filter(item => item.GroupType == 2);
      if (temporaryGroup && temporaryGroup.length > 0) {
        temporaryGroup.forEach(item => {
          if (item.GroupType == 2 && that.$store.state.soketUserInfo.gid == item.Gid) {
            //判断是否在当前临时群组
            // ws.GetGroupByGid(item.Gid, function(ret) {
            // let num = ret['array'][1][4];
            // 判断当前临时群组是否是双工群组如果是临时群组并且正在语音通话就拒绝接听
            if (item.mmingroup != 1 && that.$store.state.voiceCallInfo.callType > 0) {  //1：双工 0 正常群组
              if (!that.$store.state.voiceCallInfo.isAnswerVoice) {
                that.sendRespond(0, 0);
              }
              EventBus.$emit('closeConversation');
            }
            let bool = item.children.every(item1 => item1.Ingroup == false); //判断是否所有用户都退出临时群组
            if (item.mmingroup != 1 && bool) {
              that.endTime();
              that.LeaveGroup(item.Gid);
            }
            // });
          }
        });
      }
    },
    deweight(arr) {
      //数组去重
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    async handleNodeExpand() {
      let that = this;
      let openArray = JSON.parse(sessionStorage.getItem("openArray"));
      let radioBroadcastState = JSON.parse(
        sessionStorage.getItem("radioBroadcastState")
      );
      for (let i = 0; i < that.$store.state.groupUserData.length; i++) {
        const row = that.$store.state.groupUserData[i];
        if (openArray && openArray.indexOf(row.key) > -1) {
          //展开调用数据
          let children = JSON.parse(JSON.stringify(row.children));
          if (children.length > 0) {
            await that.hanleTree(children, i);
          }
        }
        if (radioBroadcastState && radioBroadcastState.indexOf(row.key) > -1) {
          that.$store.state.groupUserData[i].isRadioBroadcast = true;
        }
      }
      if (that.$store.state.navId == 1 && openArray || that.$store.state.navId == 3 && openArray) {
        EventBus.$emit(
          "screenData",
          JSON.stringify(that.$store.state.groupUserData)
        );
      }
      sessionStorage.setItem(
        "groupArray",
        JSON.stringify(that.$store.state.groupUserData)
      );
    },
    hanleTree(children, f) {
      let that = this;
      let array = children.map(item => item.id).join(); //集合id
      return new Promise(function (resolve, reject) {
        queryUserService({
          uId: that.$store.state.userInfo.id,
          uids: array
        }).then(res => {
          let data = [];
          for (let i = 0; i < res.data.data.length; i++) {
            for (let index = 0; index < children.length; index++) {
              const element = children[index];
              if (res.data.data[i].User_ID == element.id) {
                if (that.$store.state.groupUserData[f]) {
                  that.$store.state.groupUserData[f].children[index] = {
                    ...element,
                    ...res.data.data[i]
                  };
                }
              }
            }
          }
          resolve(data);
        });
      });
    },
    //根据Gid获得成员列表
    GetMemberList(gid, bool) {
      let that = this;
      if (that.$store.state.groupUserData.length <= 0) {
        return;
      }
      // console.log(gid)
      let index = that.$store.state.groupUserData.findIndex(
        item => item.Gid == gid
      );
      if (bool == true) {
        //bool true 强新 false
        bool = true;
      } else {
        bool = false;
      }
      if (that.$store.state.groupUserData[index]) {
        ws.GetGroupMembers(gid, bool, function (res) {
          let GroupUser = res.array[4];
          let groupMember = [];
          if (GroupUser) {
            GroupUser.forEach(item => {
              groupMember.push({
                // MemberTota: res.array[2],
                id: item[0][0],
                label: item[0][1],
                online: item[0][2], //在线 1  不在线 0,
                disabled: item[0][2] != 3,
                audioEnabled: item[0][3], //是否开启语音对讲  true  flase
                sleep: null,
                dnd: null,
                has_gid: item[0][6],
                gid: item[0][7],
                role: item[0][8],
                GPSswitch: item[0][9], //gps 定位
                locPeriod: item[0][10], //定位频率
                isBool: true,
                isOpen: false,
                Ingroup: item[1], //是否在组
                priority: item[2], //优先级0
                audioenable: item[3],
                key: item[0][7] + "" + item[0][0],
                isStartSpeech: false //语音是否通话
              });
            });
            that.$store.state.groupUserData[index].children = groupMember;
            that.$store.state.groupUserData[index].num = groupMember.filter(
              item => item.online == 3
            ).length;
            that.$store.state.groupUserData[index].total = groupMember.length;
            sessionStorage.setItem(
              "groupArray",
              JSON.stringify(that.$store.state.groupUserData)
            );
            that.handleNodeExpand();
            that.signoutGroup();
          }
        });
      }
    },
    //获取用户当前所在的群组ID
    GetCurrentGroup() {
      ws.GetCurrentGroupGid(function (ret) {
        console.info(JSON.stringify(ret["array"]));
      });
    },

    //开始讲话
    StartSpeak() {
      ws.StartSpeak();
    },

    //结束对讲
    StopSpeak() {
      if (this.$store.state.pttIntercom) {  //判断点是否正在讲话只有讲话的时候在调用
        ws.StopSpeak();
      }
    },

    //查询播放声音人的信息消息
    GetPlayingSoundUser() {
      ws.GetPlayingSoundUser(function (ret) {
        console.info("===========GetPlayingSoundUser:start===============");
        console.info(JSON.stringify(ret["array"]));
        console.info("===========GetPlayingSoundUser:end===============");
      });
    },

    //获取正在讲话的人的信息
    GetSpeakingUsers() {
      ws.GetCurrentSpeakingUser(function (ret) {
        console.info("===========GetSpeakingUsers:start===============");
        console.info(JSON.stringify(ret["array"]));
        console.info("===========GetSpeakingUsers:end===============");
      });
    },

    //获取自己当前是否正在讲话
    IsSpeaking() {
      ws.IsSpeaking(function (msg) {
        console.info("===========IsSpeaking:start===============");
        console.info(JSON.stringify(msg["array"]));
        console.info("===========IsSpeaking:end===============");
      });
    },
    //获取自己当前是否正收听语音
    IsListening() {
      ws.IsListening(function (msg) {
        console.info("===========IsSpeaking:start===============");
        console.info(JSON.stringify(msg["array"]));
        console.info("===========IsSpeaking:end=================");
      });
    },
    /*-------------------------------------------------音频记录-------------------------*/
    QueryGroupHistory() {
      ws.HistorySpeech("1172584612015112788", 0, 318681, 20, function (msg) {
        console.info("===========QueryGroupHistory:start===============");
        console.info(JSON.stringify(msg["array"]));
        console.info("===========QueryGroupHistory:end=================");
      });
    },

    PlayHistory() {
      ws.PlayHistory("1208623192173811641", 101, function (msg) {
        console.info("===========PlayHistory:start===============");
        console.info(JSON.stringify(msg["array"]));
        console.info("===========PlayHistory:end=================");
      });
    },

    /*-------------------------------------------------回调消息通知-------------------------*/

    //登录状态改变通知
    onOnlineStatusChangedX(msg) {
      console.info("onOnlineStatusChangedX:" + msg);
      if (msg == 3) {
        // this.loginState = 1;
        if (this.$route.name == "Login") {
          this.$router.push("./dashboard");
          this.$store.state.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          this.$MessageSuccess(this.$t('message.message.loginsuccessful'));
        }
        this.GetCurrentUser();
        this.GetGroupList();
      }
      if (msg == 2) {
        login_count++;
      }
      if (login_count == 10) {
        this.$MessageSuccess(this.$t('message.message.Networkconnectionfailed'));
      }
    },

    //讲话者开始讲话事件通知
    onStartPlayingX(uid, name, gid, gname) {
      let lsGroup = this.$store.state.groupUserData.filter(
        item => item.GroupType == 2
      ); //判断是否有临时群组
      this.$store.state.isSpeech = true;
      // this.suspendPlay();
      if (this.$store.state.navId == 1 || this.$store.state.navId == 3) {
        EventBus.$emit("nowSpeech", uid, gid, true);
      }
      this.$store.state.userSpeech.isBool = true;
      this.$store.state.userSpeech.userTitle = name + this.$t('message.Speaking');
      this.$store.state.userSpeech.gid = gid;
      this.$store.state.userSpeech.groupname = gname;
      this.$store.state.userSpeech.uid = uid;
      this.$store.state.userSpeech.name = name;
      this.$store.state.userSpeech.priority = 0;
      if (lsGroup.length > 0) {
        let f = lsGroup.findIndex(item => item.Gid == gid); //判断当前讲话这个用户是否是临时群组
        if (f < 0) {
          return;
        }
        let row = lsGroup[f].children.find(item => item.id == uid);
        if (row) {
          this.endTime();
        }
      }
      if (
        this.$store.state.calltThePolice != null &&
        this.$store.state.enclosureTc.length > 0 && this.$store.state.sosPushInfo.length <= 0 && this.$store.state.gbInfoText.length <= 0 && this.$store.state.gbInfoVoice.length <= 0
      ) {
        this.suspendPlay();
      }
    },
    //讲话者停止讲话事件通知
    onStopPlayingX(uid, name, gid, groupid) {
      let lsGroup = this.$store.state.groupUserData.filter(
        item => item.GroupType == 2
      ); //判断是否有临时群组
      this.$store.state.isSpeech = false;
      if (this.$store.state.navId == 1 || this.$store.state.navId == 3) {
        EventBus.$emit("nowSpeech", uid, gid, false);
      }
      this.$store.state.userSpeech.isBool = false;
      this.$store.state.userSpeech.userTitle = "";
      // if (this.$store.state.userSpeech.priority == 0) {

      // }
      if (this.isTemporaryGroup == 1 && lsGroup.length > 0) {
        let f = lsGroup.findIndex(item => item.id == gid); //判断当前讲话这个用户是否是临时群组
        if (f < 0) {
          return;
        }
        let row = lsGroup[f].children.find(item => item.id == uid);
        if (row) {
          this.startTime();
        }
      }
    },

    //开始讲话事件通知 本人开始讲话
    onStartSpeakX(gid, groupname) {
      this.$store.state.sosCallthepolice = false;
      this.$store.state.userSpeech.isShow = true;
      this.$store.state.isSpeech = true;
      this.$store.state.userSpeech.title = this.$t('message.Iamspeaking');
      this.$store.state.userSpeech.gid = gid;
      this.$store.state.userSpeech.groupname = groupname;
      this.$store.state.userSpeech.priority = 1;
      this.suspendPlay();
      this.endTime();
    },
    //停止讲话事件通知
    onStopSpeakX(gid, groupname) {
      //本人停止讲话
      this.$store.state.isSpeech = false;
      this.$store.state.userSpeech.priority = 0;
      this.$store.state.userSpeech.isShow = false;
      this.$store.state.userSpeech.title = "";
      this.$store.state.userSpeech.gid = gid;
      this.$store.state.userSpeech.groupname = groupname;
      this.$store.state.userSpeech.uid = 0;
      this.$store.state.userSpeech.name = "";
      if (this.isTemporaryGroup == 1) {
        this.startTime();
      }
    },
    suspendPlay() {
      //暂停播放sos
      if (
        this.$store.state.calltThePolice != null &&
        this.$store.state.sosPushInfo.length > 0
      ) {
        this.$store.state.calltThePolice.pause();
        this.$store.state.calltThePolice = null;
        clearInterval(this.$store.state.calltThePoliceTimer);
        // clearTimeout(this.$store.state.calltThePoliceTimer1);
      }
      if (
        this.$store.state.calltThePolice != null &&
        this.$store.state.enclosureTc.length > 0 && this.$store.state.sosPushInfo.length <= 0
      ) {
        this.$store.state.calltThePolice.pause();
        this.$store.state.calltThePolice = null;
        clearInterval(this.$store.state.calltThePoliceTimer);
        // clearTimeout(this.$store.state.calltThePoliceTimer1);
      }
      if (
        this.$store.state.gbInfoText.length > 0 &&
        this.$store.state.gbInfoText[0].msg_type == 1
      ) {
        EventBus.$emit("controlGb", 0);
      }
    },
    //调度员所在群组里，其他成员抢麦成功事件通知
    onUserStartSpeakX(uid, name, gid, group_name) {
      this.$store.state.isSpeech = true;
      // this.suspendPlay();
      if (
        this.$store.state.calltThePolice != null &&
        this.$store.state.enclosureTc.length > 0 && this.$store.state.sosPushInfo.length <= 0 && this.$store.state.gbInfoText.length <= 0 && this.$store.state.gbInfoVoice.length <= 0
      ) {
        this.suspendPlay();
      }
      this.$store.state.userSpeech.isBool = true;
      this.$store.state.userSpeech.userTitle = name + this.$t('message.Speaking');
      this.$store.state.userSpeech.gid = gid;
      this.$store.state.userSpeech.groupname = group_name;
      this.$store.state.userSpeech.uid = uid;
      this.$store.state.userSpeech.name = name;
      console.info(
        "onUserStartSpeakX : " + uid + " " + name + " " + gid + " " + group_name
      );
    },

    //调度员所在群组里，其他成员放麦成功事件通知
    onUserStopSpeakX() {
      // uid, name, gid, group_name
      this.$store.state.isSpeech = false;
      this.$store.state.userSpeech.isShow = false;
      this.$store.state.userSpeech.isBool = false;
      // this.$store.state.userSpeech.title = name + "正在讲话...";
    },
    //修改名字事件通知
    onChangeNameResultX(success) {
      console.log(success);
      if (success == 1) {
        console.info("onChangeNameResultX 修改名字成功！！");
      } else {
        console.info("onChangeNameResultX 修改名字失败！！");
      }
    },
    //修改密码通知
    onChangePasswordResultX(success) {
      if (success == 1) {
        console.info("onChangePasswordResultX 修改密码成功！！");
      } else {
        console.info("onChangePasswordResultX 修改密码失败！！");
      }
    },
    //当前用户遥开遥闭状态通知
    onAudioEnableChangedX(audio_enable) {
      // console.log(audio_enable)
      this.$store.state.audio_enable = audio_enable;
    },

    //用户状态变化通知
    onUserChangedX(array, usersListjson) {
      // console.log(
      //   array[0],
      //   usersListjson,
      //   99999999988888888888888888888888888888888888888
      // );
      let that = this;
      let userState = array[0];
      let groupUserData = that.$store.state.groupUserData;
      let groupOnline = this.$store.state.groupOnline[0];
      if (groupOnline) {
        let onlineArray = groupOnline.onlineArray;
        let i = onlineArray.findIndex(item => item.id == userState[0]);
        if (onlineArray[i] && onlineArray[i].online != userState[2]) {   //判断用户上线
          if (userState[2] == 3) {
            this.$MessageSuccess(`${userState[1]} ${this.$t('message.online')}!`, 90);
          } else {
            this.$MessageInfo(`${userState[1]} ${this.$t('message.offline')}!`, 90);
          }
        }
      }
      if (that.$store.state.userInfo.User_ID == userState[0]) {
        //判断是否是自身
        that.$store.state.userInfo.nickname = userState[1];
        return;
      }
      function into(f) {
        if (f < groupUserData.length) {
          let i =
            groupUserData.length > 0
              ? groupUserData[f].children.findIndex(
                item => item.id == userState[0]
              )
              : -1;
          if (i >= 0) {
            let changeChild = {
              ...groupUserData[f].children[i],
              id: userState[0],
              label: userState[1],
              online: userState[2],
              audioEnabled: userState[3], //是否开启语音对讲  true  flase
              has_gid: userState[6],
              role: userState[8],
              GPSswitch: userState[9],
              locPeriod: userState[10],
              disabled: userState[2] != 3,
              isBool: true
            };
            that.$store.state.groupUserData[f].children[i] = changeChild;
            that.$store.state.groupUserData[f].num = groupUserData[
              f
            ].children.filter(item => item.online == 3).length;
            that.$store.state.groupUserData[f].total =
              groupUserData[f].children.length;
          }
          into(f + 1);
        } else {
          let checkedArray = JSON.parse(sessionStorage.getItem("checkedArray"));
          if (checkedArray && that.$store.state.navId == 1) {
            EventBus.$emit("selectKey");
          }
          sessionStorage.setItem(
            "groupArray",
            JSON.stringify(that.$store.state.groupUserData)
          );
        }
      }
      into(0);
    },
    //当前所在群组变化通知
    onCurrentGroupX(gid, group_name) {
      let groupUserData = this.$store.state.groupUserData;
      let index = groupUserData.findIndex(item => item.id == gid);
      let i = groupUserData.findIndex(item => item.userState);
      if (i >= 0) {
        this.$store.state.groupUserData[i].userState = false;
      }
      if (index >= 0) {
        this.$store.state.groupUserData[index].userState = true;
      }
      if (gid && index >= 0 && groupUserData[index].GroupType != 2 && this.$store.state.voiceCallInfo.callType > 0) {  //判断通话中是否是对方挂断电话
        EventBus.$emit('closeConversation', 0);
        this.$MessageWarning(this.$t('message.message.Callended'));
      }
      this.$store.state.soketUserInfo.gid = gid;
      console.info(
        "当前所在群组变化通知" + gid + "current group name:  " + group_name
      );
    },
    //群组成员发生变化通知
    onMemberChangedX(memberchange) {
      let that = this;
      that.GetMemberList(memberchange[0], false); //刷新成员列表
      // that.GetGroupList();
    },
    //群组列表发生变化通知
    onGroupListX() {
      // grouplistjosn
      // console.info("onGroupListX :" + grouplistjosn);
      this.GetGroupList();
    },

    //结束播放录音的通知事件
    onStopPalyRecordX(msg) {
      console.info("onStopPalyRecordX : " + msg);
    },

    //异常信息提示
    onErrorX(msg) {
      // console.log(msg);
      let that = this;
      if (msg == 1) {
        that.$MessageWarning(that.$t('message.message.Accountpassworderror'));
      } else if (msg == 2) {
        that.$MessageWarning(that.$t('message.message.Arrears'));
      } else if (msg == 3) {
        that.$MessageWarning(that.$t('message.message.Accountdoesnotexist'));
        that.loginState = 0;
        setTimeout(() => {
          that.Logout();
        }, 1000);
      } else if (msg == 4) {
        that.$MessageWarning(that.$t('message.message.Permissionerror'));
      } else if (msg == 9) {
        that.$MessageWarning(that.$t('message.message.AccountInfoChange'));
        that.loginState = 0;
        setTimeout(() => {
          that.Logout();
        }, 1000);
      } else if (msg == 6) {
        that.$MessageWarning(that.$t('message.message.Configuration'));
      } else if (msg == 10) {
        that.$MessageWarning(that.$t('message.message.accountOtherlogins'));
        // that.$router.push("/Login");
        that.loginState = 0;
        setTimeout(() => {
          that.Logout();
        }, 3000)
      } else if (msg == 11) {
        that.$MessageWarning(that.$t('message.message.Accountlogintimeout'));
      } else if (msg == 20) {
        that.$MessageWarning(that.$t('message.message.Networkconnectionfailure'));
      } else if (msg == 21) {
        that.$MessageWarning(that.$t('message.message.Thenetworkisreconnecting'));
      } else if (msg == 30) {
        that.$MessageWarning(that.$t('message.message.Addinggroupfailed'));
        // that.GetGroupList();
        that.GetGroupsListRefresh();
      } else if (msg == 31) {
        that.$MessageWarning(that.$t('message.message.Addgrouprequesttimeout'));
      } else if (msg == 32) {
        that.$MessageWarning(that.$t('message.message.Singlecallfailure'));
        that.endTime();
      } else if (msg == 33) {
        that.$MessageWarning(that.$t('message.message.Failedtojointhegroup'));
      } else if (msg == 34) {
        that.$MessageWarning(that.$t('message.message.Failedtojointhegroup2'));
      } else if (msg == 35) {
        that.$MessageWarning(that.$t('message.message.Failedtojointhegroup3'));
      } else if (msg == 36) {
        that.$MessageWarning(that.$t('message.message.Failedtojointhegroup4'));
      } else if (msg == 37) {
        that.$MessageWarning(that.$t('message.message.Failedtojointhegroup5'));
        // else if (msg == 40) {
        //   that.$MessageError('抢麦被拒绝')
        // }
      } else if (msg == 41) {
        that.$MessageWarning(that.$t('message.message.noSnatchingwheat'));
      } else if (msg == 42) {
        that.$MessageWarning(that.$t('message.message.noRemoteclosureSnatchingwheat'));
        //   else if (msg == 43) {
        //   that.$MessageError('内部会话状态错误')
        // }
      } else if (msg == 44) {
        that.$MessageWarning(that.$t('message.message.Failedtograbthewheat'));
        //   else if (msg == 45) {
        //   that.$MessageError('以较低的角色值抢麦')
        // }
      } else if (msg == 46) {
        that.$MessageWarning(that.$t('message.message.Grabbingwheattimeout'));
      } else if (msg == 50) {
        that.$MessageWarning(that.$t('message.message.Failedtoopenrecordingdevice'));
      } else if (msg == 60) {
        that.$MessageWarning(that.$t('message.message.Accountlocked'));
      } else if (msg == 61) {
        that.$MessageWarning(that.$t('message.message.Terminallocked'));
      } else if (msg == 62) {
        that.$MessageWarning(that.$t('message.message.Machinecardlocked'));
      } else if (msg == 63) {
        that.$MessageWarning(that.$t('message.message.Noaccountinformationconfigured'));
      } else if (msg == 71) {
        that.$MessageWarning(that.$t('message.message.Groupcallfailure'));
        that.endTime();
      }
    },
    getPocSet(type) {    //语音对讲静音关闭
      if (type == 1) {
        ws.GetPocSetApi(1);
        // this.$store.state.userSpeech.isBool = false;
        // this.$store.state.userSpeech.isShow = false;
      } else {
        ws.GetPocSetApi(0);
      }
    }
  },
  watch: {
    "$store.state.isBroadcast"(bool) {
      //是否有语音播放
      //对数据执行操作
      if (bool == true) {
        // console.log('静音')
        this.getPocSet(1);
      } else {
        // console.log('正常')
        this.getPocSet(0);
      }
    },
    "$store.state.isTextBb"(bool) { //文字广播
      //对数据执行操作
      if (bool == true) {
        // console.log('静音')
        this.getPocSet(1);
      } else {
        // console.log('正常')
        this.getPocSet(0);
      }
    },
    "$store.state.sosCallthepolice"(bool) {   //sos
      //对数据执行操作
      if (this.$store.state.voiceCallInfo.callType == 0) {    //语音通话的时候有sos不静音对讲
        if (bool == true) {
          // console.log('静音')
          this.getPocSet(1);
        } else {
          // console.log('正常')
          this.getPocSet(0);
        }
      }
    },
    "$store.state.videoCallInfo.callType"(type) {   //视频通话中~
      if (type == 0) {
        this.getPocSet(0);
      } else {
        this.getPocSet(1);
      }
    },
    // "$store.state.videoCallInfo.switchVideo"(type) {   //视频通话中~
    //   let videoList = JSON.parse(sessionStorage.getItem("videoList"));
    //   if(videoList && videoList.length > 0){
    //     if(type == 0){
    //       this.getPocSet(0);
    //     }else{
    //       this.getPocSet(1);
    //     }
    //   }
    // }
    // "$store.state.voiceCallInfo.callType"(type) {   //语音通话中~
    //   if(type == 0){
    //     this.getPocSet(0);
    //   }else{
    //     this.getPocSet(1);
    //   }
    // }
  }
};
</script>

<style lang="less">
:root {
  /*芯对讲*/
  --main-color: #007cc2;
  --main-color-tow: #6ab5fd;
}

// @font-face {
//   font-family: 'Microsoft YaHei';
//   src: url('http://fonts.gstatic.com/ea/msyh.ttf');
// }

* {
  padding: 0;
  margin: 0;
  font-family: "Microsoft YaHei";
}

#app {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow: hidden;
  // overflow-x: hidden;
  // overflow-y: auto;
}

/* 去除百度地图版权那行字 和 百度logo */
.baidumap>.BMap_cpyCtrl {
  display: none !important;
}

// .BMap_noprint {
//   display: block !important;
// }
.anchorBL {
  display: none !important;
}

.mouse {
  cursor: pointer;
}

.mouse_dw {
  cursor: auto;
}

.my_popover {
  background: #4d4d4d !important;
  min-width: 100px !important;
  // border: 1px solid #4d4d4d  !important;
  padding: 0px !important;
  // top: 100px !important;
  border: none !important;
  border-radius: 5px !important;
}

.my_popover_one {
  background: #4d4d4d !important;
  min-width: 90px !important;
  // border: 1px solid #4d4d4d  !important;
  padding: 0px !important;
  // top: 100px !important;
  border: none !important;
  border-radius: 0px !important;
}

.ellipsis_app {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 因为谷歌地图和百度地图不一样所以样式也会有改动
.user_marker {
  width: 200px;
  padding: 20px 20px 10px 20px;
  border: 2px solid #ffffff;
  background-color: var(--main-color);
  border-radius: 5px;
  position: absolute;
  bottom: 0px;
  left: -37px;
  font-size: 14px;
  text-align: left;
  // color: #343434;

  &_box {
    width: 100%;
  }

  &_box>div {
    margin-bottom: 3px;
  }

  &_expand {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

.user_marker_left {
  left: -27px !important;
}

.userMarker_din {
  width: 240px;
  height: 220px;
  position: absolute;
  top: 0px;
  right: -240px;
  background-color: var(--main-color);
  border-radius: 5px;
  font-size: 14px;
  display: none;
  text-align: left;

  &>div {
    width: 90%;
    height: 100%;
    margin: auto;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;

    &>div>div {
      margin: 2px 0px;
    }
  }

}

.baiduinfo_box1 {
  // width: 186px;
  height: 100px;
  position: relative;
  font-size: 12px;
}

.baiduinfo_box2 {
  // width: 186px;
  // height: 100px;
  position: fixed;
  font-size: 12px;
}

.baiduinfo1 {
  text-align: left;
  width: 145px;
  padding: 10px;
  border: 2px solid #ffffff;
  background-color: var(--main-color);
  border-radius: 5px;
  position: absolute;
  bottom: 0px;
}

.baiduinfo_label1 {
  white-space: break-spaces;
}

.baidu_box {
  position: relative;
}

.baidu_lAbel {
  max-height: 220px;
  overflow-y: auto;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.baidu_label1 {
  width: 220px;
  height: 41px;
  line-height: 40px;
  margin: auto;
  background-color: var(--main-color);
  font-size: 14px;
  color: #343434;
  position: relative;
  cursor: pointer;
}

.baidu_label1:hover {
  color: #ffffff !important;
}

.baidu_label1:last-child {
  border: none !important;
}

.baidu_lAbel_fixed {
  position: fixed;
}

.baidu_lAbel_box {
  width: 80%;
  margin: auto;
  border-top: 1px solid #ffffff;
  text-align: left;
}

// .baidu_label1:last-child {
//   border: none !important;
// }

.label_border {
  border: none !important;
}

.baidu_grey {
  background-color: #C7C7C7 !important;
}

.baidu_yellow {
  background-color: #ffb500 !important;
}

.baidu_white {
  color: #666666 !important;
  background-color: #ffffff !important;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 12px !important;
}

.grey_triangle {
  border-top-color: #C7C7C7 !important;
}

.grey_yellow {
  border-top-color: #ffb500 !important;
}

.grey_white {
  border-top-color: #ffffff !important;
}

.closeLable {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 1;
}

.baidu_lAbel_space {
  white-space: break-spaces;
  word-break: break-all;
  padding-right: 10px;
}


.tipsMessageError {
  background-color: #ffffff !important;
  border: 1px solid var(--main-color) !important;
  color: red;
  text-align: center;
  justify-content: center;
}

.sosmarkerbut {
  //sos按钮
  width: 56px;
  height: 20px;
  line-height: 20px;
  background: #FF0000;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  // margin: 10px auto;
}

.prohibitCopy {
  //禁止复制
  user-select: none;
}

.margin_auto {
  margin: auto;
}


// 百度地图label三角形
.big {
  width: 0px;
  height: 0px;
  border-width: 12px 13px 0 13px;
  // border-width: 10px 10px 8px 10px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -11px;
  transform: translate(-50%);
}

.small {
  width: 0px;
  height: 0px;
  border-width: 10px 10px 0 10px;
  border-style: solid;
  border-color: var(--main-color) transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: 2px;
  transform: translate(-50%);
}


.bd_circular {
  //地位点
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main-color);
  border: 2px solid #ffffff;
  position: fixed;
}

.ds_box {
  width: 120px;
  position: absolute;
  left: -62px;
  top: -42px;
  z-index: 1000;
  padding: 5px;
  background-color: var(--main-color);
  font-size: 12px;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.ds_none {
  display: none;
}

// 谷歌
.gm-style-cc {
  //隐藏右下角文字
  display: none;
}

.gmnoprint {
  display: none;
}

.gmnoscreen {
  display: none;
}

.map_type {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
}

.map_type>img {
  width: 78px;
}

//测距
.cj_circular {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #FF0000;
  position: fixed;
}

.close_range {
  width: 12px;
  height: 12px;
  line-height: 11px;
  font-size: 15px;
  border: 1px solid #FF0000;
  background-color: #ffffff;
  color: #FF0000;
}

.googleLabel {
  opacity: 1 !important;
}

.dtround {
  min-width: 12px;
  padding: 0px 2px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid #FF0000;
  text-align: center;
  color: #FF0000;
}

// 弹性盒子
.flex_align {
  display: flex;
  align-items: center;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_one {
  flex: 1;
}
</style>
